<div class="modal-header">
  <h3 class="modal-title">Pending payment</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="emitClose.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-card-body-height">
  <div class="fs-16" *ngIf="step === 1">
    <div class="ml-2" *ngFor="let item of pendingPaymentsData; let index = index">
      <div class="d-flex justify-content-between">
        <h3>{{index + 1}}. {{item.title | capitalizeFirstLetter}}</h3>
        <h3 class="d-flex align-items-center gap-5 lh-1">
          <i
            *ngIf="item.priceByPassengerType && item.price.consumer.total"
            [ngbPopover]="priceByPassengerTypePopover"
            [autoClose]="'outside'"
            popoverClass="pax-popover"
            placement="bottom"
            class="material-icons pax-info-icon fs-22"
            container="body"
            (mouseover)="onMouseEnter()"
            (mouseleave)="onMouseLeave()"
            #popover="ngbPopover"
          >
            {{buttonName}}
          </i>
          {{item.price.consumer.total}} {{item.price.consumer.currency}}
        </h3>
      </div>
      <ng-container *ngIf="item.type === 'rebook'">
        <div class="ml-2">
          <div class="d-flex gap-30 mb-4" *ngIf="item.oldTicket?.coupons?.length">
            <div class="dark-red-color fw-500 w-40">OLD</div>
            <div class="d-flex flex-column gap-3">
              <div class="d-flex gap-20 opacity-50" *ngFor="let ticket of item.oldTicket.coupons">
                <div style="min-width: 95px;">{{ticket.segment.departure.airportCode}} → {{ticket.segment.arrival.airportCode}}</div>

                <div class="d-flex align-items-center gap-5">
                  <div>{{(ticket.segment.departure.date | dateFormat) | async}} - {{ticket.segment.departure.time}}</div>
                  <span class="material-icons fs-2">circle</span>
                  <span>Terminal {{ticket.segment.departure.terminalName || '?'}}</span>
                </div>

                <div class="d-flex align-items-center gap-5">
                  <div>{{(ticket.segment.arrival.date | dateFormat) | async}} - {{ticket.segment.arrival.time}}</div>
                  <span class="material-icons fs-2">circle</span>
                  <span>Terminal {{ticket.segment.arrival.terminalName || '?'}}</span>
                </div>

                <div>{{ticket.segment.marketingCarrier?.airlineID}} {{ticket.segment.marketingCarrier?.flightNumber}}</div>
              </div>
            </div>
          </div>

          <div class="d-flex gap-30">
            <div class="dark-green-color fw-500 w-40">NEW</div>
            <div class="d-flex flex-column gap-3">
              <ng-container *ngFor="let flight of order.flights">
                <div class="d-flex gap-20" *ngFor="let segment of flight.segments">
                  <div style="min-width: 95px;">{{segment.originDestination.departure.airportCode}} → {{segment.originDestination.arrival.airportCode}}</div>

                  <div class="d-flex align-items-center gap-5">
                    <span>{{(segment.originDestination.departure.date | dateFormat) | async}} - {{segment.originDestination.departure.time}}</span>
                      <span class="material-icons fs-2">circle</span>
                      <span>Terminal {{segment.originDestination.departure.terminalName || '?'}}</span>
                  </div>

                  <div class="d-flex align-items-center gap-5">
                    <span>{{(segment.originDestination.arrival.date | dateFormat) | async}} - {{segment.originDestination.arrival.time}}</span>
                      <span class="material-icons fs-2">circle</span>
                      <span>Terminal {{segment.originDestination.arrival.terminalName || '?'}}</span>
                  </div>

                  <div>{{segment.flightNumber | formatFlightNumber}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <hr>
      </ng-container>

      <ng-container *ngIf="item.type === 'seat'">
        <div class="d-flex flex-column gap-3 ml-2">
          <div *ngIf="item.passenger">({{item.passenger.passengerType}}) {{item.passenger.data.title}} {{item.passenger.data.name}} {{item.passenger.data.surname}}</div>
          <div *ngIf="item.segment">{{item.segment.originDestination.departure.airportCode}} → {{item.segment.originDestination.arrival.airportCode}}</div>
          <div *ngIf="item.seat">{{item.seat.location.row}}{{item.seat.location.column}}</div>
        </div>
        <hr>
      </ng-container>

      <ng-container *ngIf="item.type === 'service'">
        <div class="d-flex flex-column gap-3 ml-2">
          <div *ngIf="item.passenger">({{item.passenger.passengerType}}) {{item.passenger.data.title}} {{item.passenger.data.name}} {{item.passenger.data.surname}}</div>
          <div *ngIf="item.destinationType"> {{item.destinationType.replace('-', ' → ')}}</div>
        </div>
        <hr>
      </ng-container>
      <ng-template #priceByPassengerTypePopover>
        <div class="pax-breakdown-info-tooltip-wp">
          <h4>Price per PAX</h4>
          <div *ngFor="let pass of item.priceByPassengerType | keyvalue; last as last">
            <div class="d-flex justify-content-between">
              <span> {{pass.value.count}} {{pass.key}}</span>
              <span class="text-black"> {{pass.value.price.consumer.total}} {{pass.value.price.consumer.currency}} </span>
            </div>
            <hr *ngIf="!last">
          </div>
        </div>
      </ng-template>
    </div>

    <div class="mb-3" *ngIf="orderRepriceProcess.isProcess">
      <app-show-processing
        [processInfo]="orderRepriceProcess.processTitle">
      </app-show-processing>
    </div>

    <div class="my-4" *ngIf="orderRepriceError.message">
      <error-alert-block
        [error]="orderRepriceError"
      ></error-alert-block>
    </div>

    <ng-container *ngIf="!orderRepriceProcess.isProcess && !orderRepriceError.message">
      <div class="alert alert-warning p-3 my-4 rounded" [@fadeInOnEnter] *ngIf="orderRepriceAvailable">
        A new price might be available with <a href="#" (click)="$event.preventDefault(); repriceOrder()"><b>Reprice</b></a>.
        This process could make you lose the current offer.
      </div>
    </ng-container>
    <div class="alert alert-success p-3 my-4 rounded" *ngIf="priceChanged === false">
      Price didn't change.
    </div>
    <div class="d-flex align-items-center justify-content-end gap-12">
      <div>Total: <span [class.new-price]="priceChanged"><b>{{totalPrice | number:'1.2-2'}} {{currency}}</b></span></div>
      <button *ngIf="orderRepriceAvailable"
              class="btn btn-outline-primary p-2"
              (click)="repriceOrder()">Reprice
      </button>
    </div>
  </div>
  <ng-container *ngIf="step === 2">
    <app-payment-form
      [validateTriggered]="validateTriggered"
      [allowedPaymentMethods]="order.allowedPaymentMethods"
      [showTotalPrice]="false"
      [totalPrice]="totalPrice"
      [currency]="currency"
      [paymentMethodsExists]="paymentMethodsExists"
      [cardSurchargesMap]="order?.cardSurchargesMap"
      [owner]="order.bookingReference?.airlineID">
    </app-payment-form>
  </ng-container>

  <ng-container *ngIf="step === 3">
    <app-payment-info
      [order]="order"
      [type]="'pay_pending_elements_on_orderchange'"
      [paymentError]="orderChangeError"
      (emitPaymentInfoError)="isPaymentInfoError = $event">
    </app-payment-info>
    <app-show-processing *ngIf="pendingPaymentProcess.isProcess"
                         [processInfo]="pendingPaymentProcess.processTitle">
    </app-show-processing>
  </ng-container>

  <ng-container *ngIf="step === 4">
    <div class="alert alert-success rounded mb-0">
       Paid successfully.
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="handleSecondaryButtonClick()">
    {{step === 1 || step === 4 ? 'Close' : 'Back'}}
  </button>
  <app-popover-wrapper
    *ngIf="step === 1"
    popoverContent="Your User Role does not have the permission to proceed with this action."
    [disablePopover]="ls.role !== ROLES.BOOKER"
  >
    <button type="button"
            class="btn btn-primary"
            [disabled]="ls.role === ROLES.BOOKER"
            (click)="step = 2">
      Payment method
    </button>
  </app-popover-wrapper>
  <button *ngIf="step === 2"
          type="button"
          class="btn btn-primary"
          [disabled]="paymentService.isNonePaymentMethod"
          (click)="proceedPayment()">
    Proceed
  </button>
  <button *ngIf="step === 3"
          type="button"
          class="btn btn-primary"
          [disabled]="isPaymentInfoError"
          (click)="orderChange()">
    Proceed
  </button>
</div>
