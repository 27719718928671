import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { NDCApiService } from '../shared/services/ndc-api.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  private statusCheckInterval!: any;

  constructor(private router: Router, private ndcApiService: NDCApiService) { }

  ngOnInit(): void {
    this.startApiStatusCheck();
  }

  ngOnDestroy(): void {
    if (this.statusCheckInterval) {
      clearInterval(this.statusCheckInterval);
    }
  }

  private startApiStatusCheck(): void {
    this.checkApiStatus();

    this.statusCheckInterval = setInterval(() => {
      this.checkApiStatus();
    }, 60000);
  }

  private checkApiStatus() {
    this.ndcApiService.getApiStatus()
      .then(response => {
        if (response && response.status === 'ok') {
          this.router.navigate(['/']);
        }
      });
  }
}
