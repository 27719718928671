import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AuthService} from './shared/services/auth.service';
import {AppRoutingModule} from './app-routing.module';
import {HelpersService} from './shared/services/helpers.service';
import {HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {LocalStorageService} from './shared/services/local-storage.service';
import {GlobalErrorHandler} from './shared/handlers/global-error-handler';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {DataTransferService} from './shared/services/data-transfer.service';
import { Router, UrlSerializer } from '@angular/router';
import {CustomUrlSerializer} from './shared/handlers/custom-url-serializer';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {SharedModule} from './shared/shared.module';
import {NDCApiService} from './shared/services/ndc-api.service';
import {NotAuthorizedComponent} from './not-authorized/not-authorized.component';
import {BookingComponent} from './booking/booking.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {SeatMapService} from './shared/services/seat-map.service';
import {PaymentModuleService} from './shared/services/payment-module.service';
import {OrderModule} from './order/order.module';
import {ManagementModule} from "./management/management.module";
import {DashboardModule} from './dashboard/dashboard.module';
import {UmbrellaAuthComponent} from './umbrella-auth/umbrella-auth.component';
import {NotificationService} from './shared/services/notification.service';
import {NotFoundComponent} from './not-found/not-found.component';
import {ShowFrontGuard} from './shared/guards/show-front.guard';
import {ProfilesModule} from './profiles/profiles.module';
import {HubService} from './shared/services/hub.service';
import {HubCorporateService} from './shared/services/hub-corporate.service';
import {HubProfileService} from './shared/services/hub-profile.service';
import {MetaService} from './shared/services/meta.service';
import {ConfigService} from "./shared/services/config.service";
import {VersionService} from "./shared/services/version.service";
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { SentryService } from './shared/services/sentry.service';
import {HttpCancelInterceptor} from "./shared/interceptors/http-cancel.interceptor";
import {PendingChangesGuard} from "./shared/guards/pending-changes.guard";
import {ConfirmDialogService} from "./shared/services/confirm-dialog.service";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {PresetsModule} from "./presets/presets.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { MaintenanceComponent } from './maintenance/maintenance.component';
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {environment} from "../environments/environment";

const  initConfig = (config: ConfigService) => {
  return () => {
    return config.load();
  };
};

const initVersion = (version: VersionService) => {
  return () => {
    return version.checkCurrentVersion();
  };
};

export function migrationFactory() {
  return {
    7: (db, transaction) => {
      const webStore = transaction.objectStore('web_offer_history');
      webStore.createIndex('email', 'email', { unique: false });
      const gdsStore = transaction.objectStore('gds_offer_history');
      gdsStore.createIndex('email', 'email', { unique: false });
      const pnrStore = transaction.objectStore('pnr_offer_history');
      pnrStore.createIndex('email', 'email', { unique: false });
    },
    9: (db, transaction) => {
      const store = transaction.objectStore('web_offer_history');
      store.createIndex('preset', 'preset', { unique: false });
    }
  };
}

const dbConfig: DBConfig = {
  name: 'orderWarnTabsInfoDb',
  version: 9,
  objectStoresMeta: [{
    store: 'order_warn_tabs_info',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'comments', keypath: 'comments', options: { unique: false } },
      { name: 'comments_content', keypath: 'comments_content', options: { unique: false } },
      { name: 'last_arrival', keypath: 'last_arrival', options: { unique: false } },
      { name: 'metas', keypath: 'metas', options: { unique: false } },
      { name: 'orderID', keypath: 'orderID', options: { unique: true } },
      { name: 'order_history', keypath: 'order_history', options: { unique: false } },
      { name: 'order_log', keypath: 'order_log', options: { unique: false } },
    ]
  }, {
    store: 'web_offer_history',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'search_date', keypath: 'search_date', options: { unique: false } },
      { name: 'flight_type', keypath: 'flight_type', options: { unique: false } },
      { name: 'pax', keypath: 'pax', options: { unique: false } },
      { name: 'origin_destination', keypath: 'origin_destination', options: { unique: false } },
      { name: 'cabin', keypath: 'cabin', options: { unique: false } },
      // { name: 'preferences', keypath: 'preferences', options: { unique: false } },
      { name: 'special_discounts', keypath: 'special_discounts', options: { unique: false } },
      // { name: 'url', keypath: 'url', options: { unique: true } },
    ]
  }, {
    store: 'gds_offer_history',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'search_date', keypath: 'search_date', options: { unique: false } },
      { name: 'gds_string', keypath: 'gds_string', options: { unique: false } },
      // { name: 'url', keypath: 'url', options: { unique: true } },
    ]
  }, {
    store: 'pnr_offer_history',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'search_date', keypath: 'search_date', options: { unique: false } },
      { name: 'pnr_string', keypath: 'pnr_string', options: { unique: false } },
      // { name: 'url', keypath: 'url', options: { unique: true } },
    ]
  }, {
    store: 'passengers_info',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
    ]
  }],
  migrationFactory
};

function initializeKeycloak(keycloak: KeycloakService) {
  const clientId = typeof window !== 'undefined' && window.location.href.includes('https://web2.bookingpad.app') ? 'bookingpad2' : 'bookingpad';
  return () => keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: 'agents',
        clientId: clientId
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
    loadUserProfileAtStartUp: true,
    });
}

@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
    FooterComponent,
    HeaderComponent,
    NotAuthorizedComponent,
    BookingComponent,
    UmbrellaAuthComponent,
    NotFoundComponent,
    ConfirmDialogComponent,
    MaintenanceComponent
  ],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    ManagementModule,
    OrderModule,
    ProfilesModule,
    DashboardModule,
    PresetsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'X-Xsrf-Cookie',
      headerName: 'X-Xsrf-Token'
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  providers: [
    ConfigService,
    VersionService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initVersion,
      multi: true,
      deps: [VersionService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    AuthService,
    HubService,
    HubCorporateService,
    HubProfileService,
    DataTransferService,
    HelpersService,
    NDCApiService,
    NotificationService,
    MetaService,
    AuthGuard,
    ShowFrontGuard,
    SeatMapService,
    PaymentModuleService,
    PendingChangesGuard,
    ConfirmDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(authService: AuthService, ndcApiService: NDCApiService, ls: LocalStorageService, router: Router) {
        return new AuthInterceptor(authService, ndcApiService, ls, router);
      },
      multi: true,
      deps: [AuthService, NDCApiService, LocalStorageService, Router]
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [LocalStorageService, SentryService, NDCApiService]
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule {
}
