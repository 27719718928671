import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

export const OWNERS = {
  AF: 'AF',
  KL: 'KL'
};

export const DOCUMENT_TYPE = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Passport',
    value: 'PP',
  },
  {
    label: 'National ID',
    value: 'NI',
  }
];

export const AVAILABLE_TITLES = ['MR', 'MSTR', 'MRS', 'MS'];
export const GENDERS = ['Male', 'Female'];

export const FISCAL_IDENTITY_DOCUMENT_TYPES = ['CUIL', 'CUIT', 'CDI', 'VCI', 'RUC', 'D', 'DNI', 'CI', 'CE', 'VP', 'EP', 'ECI', 'VAT', 'NIT'];

export const ORDER_STATUS = {
  PENDING: 'Pending',
  CANCELED: 'Cancelled',
  EXPIRED: 'Expired',
  DELETED: 'Deleted',
  TICKETED: 'Ticketed',
  ISSUING: 'Issuing',
  STARTED: 'Started',
  DONE: 'Completed',
  //DISRUPTED: 'Disrupted',
  BLOCKED: 'Blocked',
  VOIDED: 'Voided',
  UNUSED: 'Unused',
  VOIDING: 'Voiding',
  CANCELLING: 'Cancelling',
};

export const ORDER_DETAIL_BUTTON_NAMES = {
  ORDER_CANCEL: 'Order Cancel',
  ORDER_VOID: 'Order Void',
  ORDER_CANCEL_REFUND: 'Cancel & Refund',
  ORDER_CANCEL_TO_VOUCHER: 'Cancel to Voucher',
};

export const ORDER_CANCEL_SCENARIO = {
  VOID: 'Void',
  CANCEL: 'Cancel',
  CANCEL_REFUND: 'Cancel to Voucher',
  VOUCHER: 'Voucher',
  REFUND: 'Refund'
};

export const ORDER_REVIEW_STATUS = {
  TRAVELER_PENDING: 'traveler_pending',
  TRAVELER_REJECTED: 'traveler_rejected',
  TRAVELER_APPROVED: 'traveler_approved',
  MANAGER_PENDING: 'manager_pending',
  MANAGER_REJECTED: 'manager_rejected',
  CUSTOMER_OK: 'customer_ok',
};

export const FLIGHT_TYPE = {
  OW: 'OW',
  RT: 'RT',
  MC: 'MC'
};

export const CABIN_TYPES_EXTENDS = [
  {
    number: 1,
    name: 'First',
    code: 'F'
  },
  {
    number: 2,
    name: 'Business',
    code: 'C'
  },
  {
    number: 4,
    name: 'Premium Economy',
    code: 'W'
  },
  {
    number: 4,
    name: 'Premium Economy',
    code: 'M'
  },
  {
    number: 5,
    name: 'Economy',
    code: 'Y'
  },
];

export const URL_PARAMS = {
  MAX_STOPS: 'max_stops',
};

export const MAX_DAYS_IN_MONTH = {
  1: 31,
  2: 29,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31
};

export const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

export const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

export const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

export const ERROR_CODES = {
  PROVIDER_ERROR: 'provider_error',
  OFFER_EXPIRED: 'offer_expired',
  ACCESS_RESOURCE_FORBIDDEN: 'access_resource_forbidden'
};


export const PAX_FORM_TITLES = {
  NAME: 'Name',
  SURNAME: 'Surname',
  DATE_OF_BIRTH: 'Date of birth',
  EMAIL: 'Email',
  PHONE: 'Phone',
  DOCUMENT_ID: 'Document ID',
  DOCUMENT_TYPE: 'Document Type',
  DOCUMENT_ISSUING_COUNTRY_CODE: 'Document Issuing Country Code',
  DOCUMENT_CITIZENSHIP_COUNTRY_CODE: 'Document Citizenship Country Code',
  DOCUMENT_RESIDENCE_COUNTRY_CODE: 'Document Residence Country Code',
  DOCUMENT_EXPIRATION_DATE: 'Document Expiration Date',
  DOCUMENT_FISCAL_NAME: 'Document Fiscal Name',
  AIRLINE_DESIGNATOR: 'Airline Designator',
  ACCOUNT_NUMBER: 'Frequent Flyer Number',
  OTHER_DOCUMENT_ID: 'Other Document ID',
  OTHER_DOCUMENT_TYPE: 'Other Document Type',
};

export const CC_FORM_TITLES = {
  CARD_NUMBER: 'Card number',
  EXPIRATION_MONTH: 'Expiration Month',
  EXPIRATION_YEAR: 'Expiration Year',
  SERIES_CODE: 'Series code',
  NAME: 'Name',
  SURNAME: 'Surname',
  EMAIL: 'Email',
  PHONE: 'Phone',
  CITY: 'City',
  POSTAL_CODE: 'Postal code',
  SURCHARGE_TYPE: 'Surcharge Type'
};

export const ROLES = {
  MANAGER: 'manager',
  ISSUER: 'issuer',
  BOOKER: 'booker',
  SUBAGENCY_MANAGER: 'subagency_manager'
};

export const PASSENGER_TYPES = ['ADT', 'YAD', 'CHD', 'INF'];

export const FAKE_AIRLINES = ['BA1', 'QF1'];

export const LOYALTY_PROGRAM_ACCOUNT_SUPPORTED_PROVIDERS = ['AF', 'BA', 'IB', 'KL', 'LH'];

export const AIRCRAFT_CODES = {
  BUS: 'BUS',
  TRAIN: ['TRS', 'TRN'],
};

export const DEFAULT_BOOKINGPAD_LOGO_PATH = 'assets/img/consumers/logos/bookingpad.svg';

export const FARELOGIX_AIRLINES = ['LH', 'A3', 'AA', 'UA', 'EK', 'QF', 'QR', 'CM', 'HA', 'WS'];
