import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output, Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { HelpersService } from '../../shared/services/helpers.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { Dictionary } from '../../shared/types/dictionary';
import { PaymentModuleService } from '../../shared/services/payment-module.service';
import {CardSurchargesMap} from "../../shared/interfaces/card-surcharges";
import {SentryService} from "../../shared/services/sentry.service";

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit, OnChanges, AfterContentChecked, OnDestroy {

  @ViewChild('loadInput') loadInput: ElementRef;

  @Input() allowedPaymentMethods;
  @Input() paymentWarnings = [];
  @Input() paymentError;
  @Input() pingProceed = 0;
  @Input() totalPrice;
  @Input() showTotalPrice = false;
  @Input() currency = '';
  @Input() cardSurchargesMap?: CardSurchargesMap;
  @Input() validateTriggered = false;
  @Input() paymentMethodsExists = true;
  @Input() logs = [];
  @Input() owner: string;
  @Output() emitFormData = new EventEmitter();

  modalContentElement: HTMLElement | null;

  countries = Dictionary.Countries;
  paymentObject;

  settings = {
    none: true,
    card: true,
    agencyCard: false,
    agencyCash: false,
  };
  tiObject;

  step = 'payment';

  surchargeTypes = [];
  showSurchargeType = false;
  surchargesInfo = '';

  monthsList: (string | number)[] = Array.from({ length: 12 }, (_, i) => i > 8 ? (i + 1) : `0${i + 1}`);
  yearsList: number[] = Array.from({ length: 25 }, (_, i) => i + 23);
  currentMonth: number;
  currentYear: number;

  warningsPerPaymentMethod = {
    card: [],
    // agencyCard: [],
    // agencyCash: []
  };

  allCardCodes = Dictionary.CardCodes;
  allowedCardCodes: { [key: string]: string } = {};
  modifiedCardCodes: { [key: string]: string } = {};
  readonly cardMethods = ['card', 'easypay', 'uatp'];


  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && (event.ctrlKey || event.altKey) && event.code === 'KeyF') {
      this.service.fillPaymentData(this.sentryService.owner, this.service.form);
      const countryCode = this.service.form.get('countryCode').value || 'es';
      this.tiObject?.setCountry(countryCode);
    }
  }

  constructor(
    public ls: LocalStorageService,
    public service: PaymentModuleService,
    public helpers: HelpersService,
    private sentryService: SentryService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pingProceed && this.pingProceed) {
      if (this.pingProceed === 1) {
        this.validateTriggered = true;
      }
      this.rebooking();
    }
  }

  ngOnInit() {
    this.setAllowedCardCodes();
    this.modifiedCardCodes = { ...this.allowedCardCodes };
    this.settings = {
      ...this.allowedPaymentMethods,
      none: false,
      uatp: !!this.allowedCardCodes['TP'] && this.allowedPaymentMethods?.card,
      easypay: !!this.allowedCardCodes['EP'] && this.allowedPaymentMethods?.card,
    };
    const currentMethod = this.service.form.get('method').value;
    this.onPaymentMethodChange(currentMethod);
    this.getWarningsPerPaymentMethod();

    this.modalContentElement = document.querySelector('.modal-content');
    if (this.modalContentElement) {
      this.renderer.addClass(this.modalContentElement, 'payment-form-modal-content');
    }

    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth() + 1;
    this.currentYear = currentDate.getFullYear() % 100;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  onPaymentMethodChange(method: string) {
    if (!this.cardMethods.includes(method)) {
      this.clearValidationForPhoneNumber();
    }

    // Function to filter allowed card codes based on criteria
    const filterCardCodes = (filterCriteria: string[]) => {
      return Object.keys(this.allowedCardCodes)
        .filter(key => filterCriteria.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.allowedCardCodes[key];
          return obj;
        }, {});
    };

    if (method === 'uatp') {
      this.modifiedCardCodes = filterCardCodes(['TP']);
      this.service.form.get('cardCode').patchValue('TP');
      this.service.form.get('surchargeType').patchValue('Corporate');
    } else if (method === 'easypay') {
      this.modifiedCardCodes = filterCardCodes(['EP']);
      this.service.form.get('cardCode').patchValue('EP');
      this.service.form.get('surchargeType').patchValue('Corporate');
    } else if (method === 'card') {
      this.modifiedCardCodes = filterCardCodes(
        Object.keys(this.allowedCardCodes).filter(key => key !== 'TP' && key !== 'EP')
      );
      // If the current cardCode is TP or EP, set it to the first available card code from modifiedCardCodes
      const currentCardCode = this.service.form.get('cardCode').value;
      if (['TP', 'EP'].includes(currentCardCode)) {
        // Get the first available card code (if any) from modifiedCardCodes
        const firstAvailableCardCode = Object.keys(this.modifiedCardCodes)[0];
        if (firstAvailableCardCode) {
          this.service.form.get('cardCode').patchValue(firstAvailableCardCode);
        }
      }
    }
    const cardCode = this.service.form.get('cardCode').value;
    const surchargeType = this.service.form.get('surchargeType').value;
    this.setSurchargesInfo(cardCode, surchargeType, method);
  }

  rebooking() {
    if (this.service.form && !this.service.form.valid) {
      this.emitFormData.emit({ isValid: false, formData: {} });
      return false;
    }

    this.paymentObject = this.service.getPaymentData();

    this.emitFormData.emit({ isValid: true, formData: this.paymentObject });

    return true;
  }

  setCountryCode(value: string) {
    if (value) {
      this.service.form.get('countryCode').setValue(value.toUpperCase());
    }
  }

  setPhoneCode(value) {
    this.service.form.get('phone').get('countryCode').setValue(value);
  }

  setCardType($event) {
    const surchargeType = this.service.form.get('surchargeType').value;
    this.setSurchargesInfo($event.target.value, surchargeType);
    this.service.form.get('cardCode').setValue(String($event.target.value));
  }

  setSurchargeType($event) {
    const cardCode = this.service.form.get('cardCode').value;
    this.setSurchargesInfo(cardCode, $event.target.value);
  }

  setSurchargesInfo(code: string, surchargeType = '', method = 'card') {
    try {
      const surchargeTypeControl = this.service.form.get('surchargeType');
      const isCard = this.cardMethods.includes(method);
      const isSupportedOwner = this.owner === 'AF' || this.owner === 'KL';

      if (isCard && this.cardSurchargesMap[code] && isSupportedOwner) {
        this.surchargeTypes = Object.keys(this.cardSurchargesMap[code]);
        this.showSurchargeType = true;
        surchargeTypeControl.setValidators([Validators.required]);
      } else {
        this.surchargeTypes = [];
        this.showSurchargeType = false;
        surchargeTypeControl.clearValidators();
      }
      if (!this.surchargeTypes.includes(surchargeTypeControl.value) && isCard) {
        surchargeTypeControl.setValue('');
      }
      surchargeTypeControl.updateValueAndValidity();

      if (surchargeType && this.cardSurchargesMap[code][surchargeType]) {
        this.surchargesInfo = this.cardSurchargesMap[code][surchargeType].consumer.total + ' '
          + this.cardSurchargesMap[code][surchargeType].consumer.currency;
      } else {
        this.surchargesInfo = '';
      }
    } catch (e) {
      this.surchargesInfo = '';
      this.showSurchargeType = false;
    }
  }

  onYearSelect(year: string) {
    const monthControl = this.service.form.get('expiration_month');
    if (parseInt(year) === this.currentYear && monthControl.value < this.currentMonth) {
      monthControl.setValue('');
    }
  }

  getNumber($event: any) {
    // console.log('gn',$event);
  }

  telInputObject($event: any) {
    this.tiObject = $event;
    let selectedCountry = this.service.form.get('phone').get('countryCode').value;
    if (selectedCountry) {
      let savedTelCountry = this.ls.settings?.telCountryNumber;
      this.setPhoneCode(savedTelCountry?.dialCode || $event.s.dialCode);
      this.setCountryCode(savedTelCountry?.iso || $event.s.iso2);
    }
    this.helpers.onTelInputObject(this.tiObject, selectedCountry);
  }

  onCountryChange($event: any) {
    this.setPhoneCode($event.dialCode);
    this.setCountryCode($event.iso2);
    this.helpers.saveTelCountryNumber($event);
  }

  clearValidationForPhoneNumber() {
    const phoneNumber = this.service.form.get('phone.number');
    phoneNumber.clearValidators();
    phoneNumber.updateValueAndValidity();
  }

  getWarningsPerPaymentMethod() {
    const itemWithCode33 = this.logs?.slice().reverse().find(item => item.text.includes("code: 33"));

    if (itemWithCode33) {
      const msgIndex = itemWithCode33.text.indexOf("msg: ");
      const warningMessage = msgIndex !== -1 ? itemWithCode33.text.slice(msgIndex + 5) : undefined;

      if (warningMessage && !this.warningsPerPaymentMethod.card.includes(warningMessage)) {
        this.warningsPerPaymentMethod.card.push(warningMessage);
      }
    }
  }

  setAllowedCardCodes() {
    if (
      this.cardSurchargesMap &&
      typeof this.cardSurchargesMap === 'object' &&
      Object.keys(this.cardSurchargesMap).length
    ) {
      this.allowedCardCodes = Object.keys(this.allCardCodes)
        .filter(code => this.cardSurchargesMap.hasOwnProperty(code))
        .reduce((acc, code) => {
          acc[code] = this.allCardCodes[code];
          return acc;
        }, {});
    } else {
      this.allowedCardCodes = this.allCardCodes;
    }
  }

  get hasCustomPayment() {
    return (
      ['none', 'agencyCard', 'agencyCash'].indexOf(
        this.service.form.get('method').value
      ) === -1
    );
  }

  get isCardPayment() {
    return (
      this.cardMethods.indexOf(this.service.form.get('method').value) > -1
    );
  }

  get isCardMethod(): boolean {
    return this.service.form.get('method')?.value === 'card';
  }

  ngOnDestroy() {
    if (this.modalContentElement) {
      this.renderer.removeClass(this.modalContentElement, 'payment-form-modal-content');
    }
  }

}
